import React from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Spin, ConfigProvider, Card, Row, Col, Button } from 'antd';
import { UilFileDownload, UilTrash } from '@iconscout/react-unicons';
import { FileAltIcon } from '../../features/Icon/Icons';
import { useQuery } from '@tanstack/react-query';
import { hrApi } from '../../utils/apiConfig';
import { apiUrl } from '../../utils/config';
import moment from 'moment';
import { dateFormatConvert } from '../../utils/config';
import '../../styles/person-documents-tab.scss';

// API çağrısı için yeni fonksiyon
const getFileUploadList = async () => {
    const config = {
        method: 'get',
        url: `${apiUrl}/persons/getFileUploadListForMe`,
    };
    const { data } = await hrApi(config);
    return data;
};

function MyDocuments() {
    const { t } = useTranslation();

    // useQuery hook'u ile veri çekme
    const { data: fileUploadListData, isLoading, error } = useQuery(
        ['fileUploadList'],
        getFileUploadList,
        {
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    const downloadDocument = (link) => {
        return window.open(link, '_blank', 'noopener,noreferrer')
    }    

    // Hata durumu kontrolü
    if (error) {
        return (
            <div className="empty-state-container">
                <p className="empty-state-text">{t('NO_DOCUMENTS_FOUND')}</p>
            </div>
        );
    }

    return (
        isLoading ? (
            <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
        ) : (
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                    <div className='document-upload-card-container'>
                        {fileUploadListData?.data?.length > 0 && fileUploadListData.data.filter(item => item.isUploaded).length > 0 ? (
                            fileUploadListData.data.filter(item => item.isUploaded).map((data, index) => {
                                return <Card key={index} className='document-upload-card my-3'>
                                    <Row>
                                        <Col span={1}>
                                            <FileAltIcon />
                                        </Col>
                                        <Col span={19}>
                                            <Row gutter={[0, 10]}>
                                                <Col span={24}>
                                                    <span className='document-text'>{data.documentTypeName}</span>
                                                </Col>
                                                <Col span={24}>
                                                    <span className='document-text'>{t('FILE_NAME') + ' : ' + data.organizationDocumentName}</span>
                                                </Col>
                                                <Col span={24}>
                                                    <span className='document-text'>{data.uploadedDate && t('UPLOAD_DATE') + ' : ' + moment(data.uploadedDate).format(dateFormatConvert)}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4}>
                                            <Col span={24}>
                                                <div className='document-action-buttons-right'>
                                                    <Button className="w-180-h-40 transparent-btn" onClick={() => downloadDocument(data.fileName)}><UilFileDownload /></Button>
                                                </div>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Card>
                            })
                        ) : (
                            <div className="empty-state-container">
                                <p className="empty-state-text">{t('NO_DOCUMENTS_FOUND')}</p>
                            </div>
                        )}
                    </div>
            </ConfigProvider >
        )
    )
}

export default MyDocuments;
