import React, { useState, useEffect } from "react";
import { Button, Tabs, Drawer, Image, Spin, Row, Col, Layout } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import { getPersonMe } from '../../redux/actions/person.actions';
import { setChangePasswordDrawer, changePasswordReset } from "../../redux/actions/profile.actions";
import {
    setAssignmentDetailTabStatus,
    setAssignmentDetailAddTabStatus, setAssignmentDetailAddOpenDraw,
    setSalaryDetailsOpenDraw, setSalaryAddOpenDraw, setAdvanceDetailOpenDraw,
    setAdvanceAddOpenDraw, setPersonAddOpenDraw,
} from '../../redux/actions/person-detail-tab-action';
import { accountContracts } from '../../redux/actions/organization.actions';

import ProfileBasicInformation from '../../component/profileTabs/basic-information';
import ProfilPersonInformation from '../../component/profileTabs/person-information';
import OwnerShipList from '../../component/personTabs/owner-ship-list';
import AssignmentList from '../../component/personTabs/assignment-list.tab';
import AssignmentDetails from '../../component/personTabs/assignment-details-form';
import AssignmentDetailsAdd from '../../component/personTabs/assignment-details-add-form';
import SalaryInformation from '../../component/personTabs/salary-information-list';
import SalaryDetails from '../../component/personTabs/salary-information-details';
import AddSalaryInformation from '../../component/personTabs/salary-information-add';
import AdvanceRequestDetails from '../../component/personTabs/advance-request-details';
import AdvanceRequestAdd from '../../component/personTabs/advance-request-add';
import OwnerShipDetails from '../../component/personTabs/ownership-details';
import ProfileContact from '../../component/profileTabs/contact';
import ProfileSocialMedia from '../../component/profileTabs/social-media';

import DynamicTable from '../../component/dynamicTable/index';
import { drawerWidth, dateFormatConvert } from '../../utils/config';
import { resetScrollPosition } from '../../utils/commonFormFunctions';
import { ToastContainer } from 'react-toastify';
import '../../styles/tab-page.scss';
import MyDocuments from '../../component/profileTabs/my-documents';

function Profile(props) {
    const { t } = useTranslation();
    const { TabPane } = Tabs;
    const { Content } = Layout;
    const {
        tabreducer, personDebitDetailsIsFetching, personDebitDetails, profile, setChangePasswordDrawer,
        changePasswordReset, setAssignmentDetailAddTabStatus, setDebitDetailsDraw, getPersonMe, person,
        isPersonLoading, salaryHistory, jobHistory, auth, accountContracts, contractItems, contractItemsLoading
    } = props;
    const [currentTab, setCurrentTab] = useState("PersonBasicInfo");
    const [searchData, setSearchData] = useState({
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "name",
            "ascending": true
        },
    });
    const selectedPersonId = auth.user.profile.resourceid;
    const [cKey, setCKey] = useState(0)
    const page = 'profile';
    const ownerShipInitialData = {
        "searchTerm": "",
        "pagination": {
            "pageNumber": 1,
            "pageSize": 10,
            "orderBy": "Name",
            "ascending": true
        },
        "inventoryTypes": []
    }
    const assignmentAddHide = () => {
        setAssignmentDetailAddTabStatus(false);
    }
    const getDebitDetailsUpdateObj = () => { }
    const setPersonUpdateObject = () => { }

    const setChangePasswordDrawerStatus = (s) => {
        setCKey(cKey + 1)
        changePasswordReset()
        setChangePasswordDrawer(s)
    }
    function callback(key) {
        setCurrentTab(key);
        resetScrollPosition('.user-scrollable-tab > .ant-tabs-content-holder');
        if (key === 'terms') {
            accountContracts();
        }
    }

    const columns = [
        {
            title: `${t('DOCUMENT_TYPE')}`,
            dataIndex: 'contractName',
            key: 'contractName',
            sorter: false,
        },
        {
            title: `${t('SIGNATURE_DATE')}`,
            dataIndex: 'timestampDate',
            key: 'timestampDate',
            sorter: false,
            render: (date) => {
                return date ? moment(date).format(dateFormatConvert) : null
            }
        },
        {
            title: '',
            dataIndex: 'fileUrl',
            key: 'fileUrl',
            sorter: false,
            render: (url) => {
                return <a className="blue fw-600" href={url} target="_blank">{t('PARTNER_DOWNLOAD')}</a>
            }
        },
    ];
    const data = contractItems?.length > 0 ? contractItems.map((item, index) => {
        return (
            {
                'key': index,
                'contractName': item?.contractName,
                'fileUrl': item?.fileUrl,
                'timestampDate': item?.timestampDate
            }
        )
    }) : [];
    useEffect(() => {
        getPersonMe()
    }, []);
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        visibilityOptionExcludes: ['contractName', 'timestampDate', 'fileUrl'],
        gridSettings: null,
        page: "terms",
        showVisibilityMenu: false
    });
    const paginationConfig = () => ({
        current: 1,
        total: 100,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: 1 < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    })
    const handleTableChange = (data) => {
        const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }

        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const handleTableFilter = () => { }
    const handleResetAllfilters = () => { }
    const handleShowPassivesToogle = () => { }
    const handleSaveGridSettings = () => { }

    return (
        <Layout>
            <Content className="page-container page-container-pad-left-0" >
                <Drawer title={false} width={drawerWidth} placement="right" closable={false} visible={tabreducer.openAssignmentDraw} >
                    <AssignmentDetails person={person} />
                </Drawer>

                <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={assignmentAddHide} visible={tabreducer.openAssignmentAddDraw} >
                    <AssignmentDetailsAdd person={person} />
                </Drawer>

                <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={setSalaryDetailsOpenDraw} visible={tabreducer.salaryDetailsDraw} >
                    <SalaryDetails personResourceId={person.resourceId} />
                </Drawer>

                <Drawer title={false} width={875} placement="right" closable={false} onClose={setSalaryAddOpenDraw} visible={tabreducer.salaryAddDraw} >
                    <AddSalaryInformation person={person} />
                </Drawer>

                <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={() => props.setAdvanceDetailOpenDraw(false)} visible={tabreducer.advanceDetailsDraw} >
                    <AdvanceRequestDetails />
                </Drawer>

                <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={() => props.setAdvanceAddOpenDraw(false)} visible={tabreducer.advanceAddDraw} >
                    <AdvanceRequestAdd />
                </Drawer>

                <Drawer title={false} width={drawerWidth} placement="right" closable={false} onClose={() => setDebitDetailsDraw({ 'tabStatus': false, "id": null })} visible={tabreducer.debitDetailsDraw.tabStatus} >
                    <OwnerShipDetails personDebitDetails={personDebitDetails} personDebitDetailsIsFetching={personDebitDetailsIsFetching} getUpdateObj={(o) => getDebitDetailsUpdateObj(o)} />
                </Drawer>
                <div className="page-container-pad-left-0">
                    <h1 className="page-title2">{t('MY_INFORMATION')}</h1>
                    <div className="pad-15" />
                    <Row>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 8 }} xxl={{ span: 6 }}  >
                            {isPersonLoading ?
                                <div className="person-information-left-container">
                                    <Spin className="tab-loading" size="large" tip={`${t('LOADING')}`}></Spin>
                                </div>
                                :
                                <div className="person-information-left-container">
                                    <Image src={person.photo ? person.photo : process.env.REACT_APP_URL + 'images/avatar_' + profile.gender?.id + '.png'} preview={false} />
                                    <div className="user-details">
                                        <h1>{person.givenName + " " + person.familyName}</h1>
                                        <div className="profile-info-alt">
                                            {person?.job?.displayValue &&
                                                <div className="profile-info-alt-item">
                                                    <div className="profile-info-alt-item-text">
                                                        <h3>{t('PARTNER_JOB2') + ' :'}</h3>
                                                    </div>
                                                    <div className="profile-info-alt-item-text">
                                                        <h3>{person?.job?.displayValue}</h3>
                                                    </div>
                                                </div>}
                                            {person?.corporateEmail &&
                                                <div className="profile-info-alt-item">
                                                    <div className="profile-info-alt-item-text">
                                                        <h3 className="email">{t('ORGANIZATION_E_MAIL') + ' :'}</h3>

                                                    </div>
                                                    <div className="profile-info-alt-item-text">
                                                        <h3 className="email">{person.corporateEmail}</h3>
                                                    </div>
                                                </div>}
                                            {person?.workPhoneExt &&
                                                <div className="profile-info-alt-item">
                                                    <div className="profile-info-alt-item-text">
                                                        <h3>{t('PARTNER_WORK_PHONE_EXT') + ' :'}</h3>
                                                    </div>
                                                    <div className="profile-info-alt-item-text">
                                                        <h3>{person.workPhoneExt}</h3>
                                                    </div>
                                                </div>}
                                            {person?.identityNumber &&
                                                <div className="profile-info-alt-item">
                                                    <div className="profile-info-alt-item-label">
                                                        <h3>{t('PARTNER_IDENTITY_NUMBER') + ' :'}</h3>
                                                    </div>
                                                    <div className="profile-info-alt-item-text">
                                                        <h3>{person.identityNumber}</h3>
                                                    </div>
                                                </div>}
                                        </div>
                                        <Button className="change-password-btn white-btn" onClick={() => setChangePasswordDrawerStatus(true)}> {t('HEADER_CHANGE_PASSWORD')}</Button>
                                    </div>
                                </div>
                            }
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 14 }} xl={{ span: 16 }} xxl={{ span: 18 }} >
                            <div className="table-details person-information-tabs">
                                <Tabs
                                    className={"custom-tab user-info-scrollable-tab"}
                                    onChange={callback}
                                    defaultActiveKey="PersonBasicInfo"
                                    activeKey={currentTab}
                                >
                                    <TabPane tab={`${t('PARTNER_BASIC_INFORMATION')}`} key="PersonBasicInfo">
                                        <ProfileBasicInformation personBasicInfo={person} personBasicInfoIsFetching={isPersonLoading} />
                                    </TabPane>
                                    <TabPane tab={`${t('PARTNER_PERSONAL_INFORMATION')}`} key="PersonelInformation" >
                                        <ProfilPersonInformation personInformation={person} personInformationIsFetching={isPersonLoading} />
                                    </TabPane>
                                    <TabPane tab={`${t('PARTNER_OWNERSHIP')}`} key={"ownerShip"} >
                                        <OwnerShipList disableStatus={true} page={page} selectedPersonId={selectedPersonId} ownerShipInitialData={ownerShipInitialData} editable={false} />
                                    </TabPane>

                                    <TabPane tab={`${t('PARTNER_SALARY_INFORMATION2')}`} key={"Salary"}>
                                        <SalaryInformation person={person} page={page} salaryHistory={salaryHistory} getUpdateObj={(o) => setPersonUpdateObject(o)} editable={false} />
                                    </TabPane>
                                    <TabPane tab={`${t('PARTNER_APPOINTMENTS')}`} key={"JobHistory"}>
                                        <AssignmentList person={person} page={page} jobHistory={jobHistory} editable={false} />
                                    </TabPane>
                                    <TabPane tab={`${t('PARTNER_CONTACT')}`} key="contactTab">
                                        <ProfileContact personContactDetails={person} personContactIsFetching={isPersonLoading} />
                                    </TabPane>

                                    <TabPane tab={`${t('PARTNER_CUSTOM_FIELDS')}`} key="socialmediaTab">
                                        <ProfileSocialMedia disableStatus={true} socialmedia={person} personSocialIsFetching={isPersonLoading} getUpdateObj={(o) => setPersonUpdateObject(o)} />
                                    </TabPane>
                                    <TabPane tab={`${t('MY_CONTRACTS')}`} key="terms">
                                        <div className="table-container">
                                            <DynamicTable
                                                columns={columns} dataSource={data}
                                                tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                                                onTableChange={(e) => handleTableChange(e)} onResetAllFilters={handleResetAllfilters}
                                                searchPlaceholder=""
                                                onShowPassivesToggle={(e) => handleShowPassivesToogle(e)}
                                                isShowPassives={false}
                                                onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                                                searchBox={false}
                                                checkboxStatus={false}
                                                passiveButton={false}
                                                hideScroll={true}
                                                loadStatus={contractItemsLoading}
                                            />
                                        </div>
                                    </TabPane>

                                    <TabPane tab={`${t('MY_PARTNER_DOCUMENTS')}`} key="myDocumentsTab">
                                        <MyDocuments />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>

                </div>
                <ToastContainer />
            </Content>
        </Layout>
    )
}

const mapStateToProps = function (state) {
    return {
        person: state.person.personMe,
        isPersonLoading: state.person.isPersonMeLoading,
        salaryHistory: state.person.salaryHistory,
        isSalaryHistoryLoading: state.person.isSalaryHistoryLoading,
        jobHistory: state.person.jobHistory,
        tabreducer: state.tabreducer,
        auth: state.auth,
        profile: state.profile,
        changePasswordDrawerStatus: state.profile.changePasswordDrawer,
        contractItems: state.organization.contractItems,
        contractItemsLoading: state.organization.contractItemsLoading
    }
}
const mapDispatchToProps = function (dispatch) {
    return {
        setAssignmentDetailTabStatusEdit: (props) => { dispatch(setAssignmentDetailTabStatus(props)); },
        setAssignmentDetailAddTabStatus: (props) => { dispatch(setAssignmentDetailAddTabStatus(props)); },
        setAssignmentDetailAddOpenDraw: (props) => { dispatch(setAssignmentDetailAddOpenDraw(props)); },
        setSalaryDetailsOpenDraw: (props) => { dispatch(setSalaryDetailsOpenDraw(props)); },
        setSalaryAddOpenDraw: (props) => { dispatch(setSalaryAddOpenDraw(props)); },
        setAdvanceDetailOpenDraw: (props) => { dispatch(setAdvanceDetailOpenDraw(props)); },
        setAdvanceAddOpenDraw: (props) => { dispatch(setAdvanceAddOpenDraw(props)); },
        setPersonAddOpenDraw: (props) => { dispatch(setPersonAddOpenDraw(props)) },
        getPersonMe: () => { dispatch(getPersonMe()) },
        setChangePasswordDrawer: (s) => { dispatch(setChangePasswordDrawer(s)) },
        changePasswordReset: () => { dispatch(changePasswordReset()) },
        accountContracts: () => { dispatch(accountContracts()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile)